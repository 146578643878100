import React from "react";

export const A = ({ className, children, ...restProps }) => {
  return (
    <a
      className={"underline " + (className || "")}
      target="_blank"
      rel="noreferrer"
      {...restProps}
    >
      {children}
    </a>
  );
};
