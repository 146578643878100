import React, { useState, useEffect } from "react";

export const MyEmail = () => {
  const [showEmail, setShowEmail] = useState(false);

  useEffect(() => {
    const tid = setTimeout(() => {
      setShowEmail(true);
    }, 500);

    return () => {
      clearTimeout(tid);
    };
  }, []);

  return showEmail ? (
    <a href="mailto:hello@its.beer">hello@its.beer</a>
  ) : (
    <>anti-spam placeholder</>
  );
};
